import { ReactSession } from "react-client-session";

export class FoamFactoryAPI {
  // Generic Methods
  performAuthenticatedGet(path) {
    const auth_token = this.getAuthenticatedUser().auth_token;

    const fetchConfig = {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${auth_token}`,
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    };

    return fetch(path, fetchConfig)
      .then((response) => {
        return response.json();
      });
  }

  performAuthenticatedPost(path, body) {
    const auth_token = this.getAuthenticatedUser().auth_token;

    const fetchConfig = {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${auth_token}`,
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    };

    return fetch(path, fetchConfig)
      .then((response) => {
        return response.json();
      });
  }

  performAuthenticatedPatch(path, body) {
    const auth_token = this.getAuthenticatedUser().auth_token;

    const fetchConfig = {
      method: "PATCH",
      headers: {
        "Authorization": `Bearer ${auth_token}`,
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    };

    return fetch(path, fetchConfig)
      .then((response) => {
        return response.json();
      });
  }

  // Version Info
  getVersion() {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  // User and Authentication Methods
  getUserById(userId) {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  getAuthenticatedUser() {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  updateAvatarForCurrentUser(avatarUrl) {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  login(username, password) {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  changePassword() {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  getUsers() {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  getAllProcesses() {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  getProcessById(id) {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  /**
   * Retrieve a phase by its id, containing process id and containing stage id.
   *
   * @param processId [string] The process id of the process containing the
   *        stage and phase desired.
   * @param stageId [string] The stage id of the stage within the process
   *        containing the phase desired.
   * @param phaseId [string] The id of the phase desired.
   *
   * @return The {@link Phase} data structure, as a JSON object, if one exists
             having the aforementioned parameters; `null`, otherwise.
   */
  getPhaseByProcessAndStageId(processId, stageId, phaseId) {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  getIngredientsForProcessAndPhases(processId, phases) {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  getIngredientsInRecipeByStagePhaseAndType(recipe, stage, phase, type) {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  getAllIngredientsOfType(type) {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  getIngredientByTypeAndId (type, id) {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  getFermentableById(id) {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  getAllFermentables() {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  // Yeasts
  getAllYeasts() {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  getYeastById(id) {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  getHopById(id) {
    const functionName = new Error().stack.split('\n')[0].split('@')[0].trim();
    return new Promise((resolve, reject) => {
      reject(`${functionName}() is not implemented in ${this.constructor.name}`);
    });
  }

  getHop(hopId) {
    return this.performAuthenticatedGet(`${this._baseUrl}/hops/${hopId}`);
  }

  updateHop(hopId, hop) {
    return  this.performAuthenticatedPatch(`${this._baseUrl}/hops/${hopId}`, hop);
  }

  getAllHops() {
    return this.performAuthenticatedGet(`${this._baseUrl}/hops`);
  }

  addHop(newHop) {
    return this.performAuthenticatedPost(`${this._baseUrl}/hops`, newHop);
  }

  /**
   * Retrieve all log entries for the given user.
   */
  getAllLogEntries() {
    return this.performAuthenticatedGet(`${this._baseUrl}/log_entries`);
  }

  /**
   * Retrieve all batch objects for the current user.
   */
  getAllBatches() {
    return this.performAuthenticatedGet(`${this._baseUrl}/batches`);
  }

  addLogEntry(data) {
    return this.performAuthenticatedPost(`${this._baseUrl}/log_entries`, data);
  }

  logout() {
    ReactSession.remove('authenticatedUser');

    return Promise.resolve();
  }
}
