import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";

import { Dashboard } from './components/05-Organisms/Dashboard';
import { ErrorView } from './components/05-Organisms/ErrorView';
import { FermentablesLibrary, FermentablesLibraryDetail } from './components/05-Organisms/FermentablesLibrary';
import { FoamFactoryAPIManager } from './api/FoamFactoryAPIManager';
import { HopsLibrary, HopsLibraryDetail } from './components/05-Organisms/HopsLibrary';
import { Logbook } from './components/05-Organisms/Logbook';
import { LoginView } from './components/05-Organisms/LoginView';
import { LogoutView } from './components/05-Organisms/LogoutView';
import { RecipeDesigner } from './components/05-Organisms/RecipeDesigner';
import { RecipeBook } from './components/05-Organisms/RecipeBook';
import { UserProfile } from './components/05-Organisms/UserProfile';
import { Icons, Workbench } from '@foamfactory/aegir';
import { SessionUtil } from './util/SessionUtil';

import logoFile from './assets/images/Logo.svg';

import './assets/styles/icons.scss';
import './assets/styles/App.scss';

import * as packageJson from '../package.json';

const MENU_STRUCTURE = [
  {
    label: 'Dashboard',
    location: '/dashboard'
  },
  {
    label: 'Libraries',
    subMenu: [
      {
        label: 'Fermentables',
        location: '/library/fermentables'
      },
      {
        label: 'Hops',
        location: '/library/hops'
      }
    ]
  },
  {
    label: 'Tools',
    subMenu: [
      {
        label: 'Create Recipe',
        location: '/tools/recipe-designer'
      },
      {
        label: 'Recipe Book',
        location: '/tools/recipe-book'
      },
      {
        label: 'Logbook',
        location: '/tools/logbook'
      },
      {
        label: 'Hop Calculator',
        location: '/tools/hop-calculator'
      },
      {
        label: 'Equipment Manager',
        location: '/tools/equipment-manager'
      }
    ]
  },
  {
    label: 'Account',
    subMenu: [
      {
        label: 'My Profile',
        location: '/profile'
      },
      {
        label: 'Logout',
        location: '/logout'
      }
    ]
  }
];

export default class App extends React.PureComponent {
  constructor(props) {
    super(props);

    console.log(`FoamFactory App Version: ${packageJson.version}`);
    console.log(`Running in app environment: ${process.env.REACT_APP_FOAMFACTORY_ENVIRONMENT}`);

    // This is a reference to the RecipeDesigner. It will get created when the
    // recipe designer is mounted, and destroyed when the recipe designer is
    // unmounted.
    this.recipeDesignerRef = React.createRef();

    this.onSave = this.onSave.bind(this);

    this.apiPromise = FoamFactoryAPIManager.getApi();
    this.state = {
      authenticatedUser: SessionUtil.getAuthenticatedUser()
    };
  }

  onSave() {
    // TODO: This needs to change to reflect the context-sensitivity of saving (e.g. in Profile vs. Recipe Designer).
    return new Promise((resolve, reject) => {
      if (!this.recipeDesignerRef) {
        reject("Unable to save because reference to Recipe Designer is undefined or null");
      } else if (!this.recipeDesignerRef.current) {
        reject ("Unable to save because reference to Recipe Designer does not have a current member");
      }

      return this.recipeDesignerRef.current.save().then(
        () => {
          resolve("Saved!");
        }
      );
    });
  }

  componentDidMount() {
    // Check to see if the API is working by pulling the version, and if not, render an error page
    if (!this.state.error) {
      this.apiPromise
        .catch((err) => {
          this.setState({
            error: err
          })
        });
    }
  }

  render() {
    let self = this;

    if (this.state.error) {
      // We encountered an error with the API, so we need to report this
      // console.log("Error encountered: ", this.state.error);
      return (
        <ErrorView
          title={"API Initialization Error"}
          description={<span><p>There was an issue initializing the API. We were unable to connect to <strong>{this.state.error.baseUrl}</strong>.</p><p>This is likely a service-level issue with the FoamFactory API, meaning that this will be resolved when our engineers are able to restore service. Please note we're working on the issue and will have it fixed as quickly as possible.</p></span>}
          icon={Icons.BarrelWithBrokenTap}
        />
      );
    }

    if (this.state && this.state.authenticatedUser) {
      return (
        <Router>
          <Switch>
            <Route exact path="/logout">
              <LogoutView />
            </Route>
            <Route path="/">
              <Workbench
                logoPath={logoFile}
                onSave={this.onSave}
                menuStructure={MENU_STRUCTURE}>
                  <Route exact path="/">
                    <Redirect to="/dashboard" />
                  </Route>
                  <Route path="/dashboard">
                    <Dashboard />
                  </Route>
                  <Route
                    exact
                    path="/library/fermentables"
                  >
                    <FermentablesLibrary />
                  </Route>
                  <Route
                    exact
                    path="/library/fermentables/add"
                  >
                    <FermentablesLibraryDetail />
                  </Route>
                  <Route
                    exact
                    path="/library/fermentables/update/:fermentableId"
                    render={(props) => {
                      return (
                        <FermentablesLibraryDetail
                          fermentableId={props.match.params.fermentableId} />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/library/hops"
                  >
                    <HopsLibrary />
                  </Route>
                  <Route
                    exact
                    path="/library/hops/add"
                  >
                    <HopsLibraryDetail />
                  </Route>
                  <Route
                    exact
                    path="/tools/logbook">
                      <Logbook />
                  </Route>
                  <Route
                    exact
                    path="/library/hops/update/:hopId"
                    render={(props) => {
                      return (
                        <HopsLibraryDetail
                          hopId={props.match.params.hopId} />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/recipes/:recipeId"
                    render={(props) => {
                      return (
                        <RecipeDesigner
                          ref={self.recipeDesignerRef}
                          recipeId={props.match.params.recipeId} />
                      );
                    }}
                  />
                  <Route path="/tools/recipe-designer" render={(props) => {
                    return (
                      <RecipeDesigner
                        ref={self.recipeDesignerRef} />
                    );
                  }}>
                  </Route>
                  <Route path="/tools/recipe-book">
                    <RecipeBook />
                  </Route>
                  <Route path="/profile">
                    <UserProfile />
                  </Route>
              </Workbench>
            </Route>
          </Switch>
        </Router>
      );
    } else {
      return (
        <Router>
          <Switch>
          <Route path="/login">
              <LoginView />
            </Route>
            <Route path="/">
              <Redirect to="/login" />
            </Route>
          </Switch>
        </Router>
      );
    }
  }
}
