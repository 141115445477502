import React from 'react';
import $ from 'jquery';
import _ from 'lodash';

import {
        Icons,
        IconButton,
        StandardButton,
        Tool,
        Toolbelt,
        ToolContainer
       } from '@foamfactory/aegir';

import { LibraryUtil } from '../../util/LibraryUtil';

import { FoamFactoryLocalStorageAPI } from '../../api/FoamFactoryLocalStorageAPI';

import './FermentablesLibrary.scss';

export class FermentablesLibraryDetail extends React.PureComponent {
  constructor(props) {
    super(props);

    this.api = new FoamFactoryLocalStorageAPI();

    this.processSubmission = this.processSubmission.bind(this);
  }

  processSubmission(event) {
    event.preventDefault();

    const description = $(`#fermentable-description`).val();
    const manufacturer = $(`#fermentable-manufacturer`).val();
    const color = $(`#fermentable-color`).val();
    const ppg = $(`#fermentable-ppg`).val();
    const country = $(`#fermentable-country`).val();

    let fermentable = {
      attributes: [
        { description: description },
        { manufacturer: manufacturer },
        { color: color },
        { ppg: ppg },
        { country: country }
      ],
    };

    const fermentableKey
      = LibraryUtil.getCompositeKeyForLibraryObject(fermentable,
                                                    ['description',
                                                     'manufacturer',
                                                     'country'])
    const fermentableByKey = this.api.getFermentableByKey(fermentableKey);

    if (this.props.fermentableId || fermentableByKey) {
      const id = this.props.fermentableId || fermentableByKey.id;

      this.api.updateFermentable(id, fermentable);
    } else {
      this.api.addFermentable(fermentable);
    }

    window.location = '/library/fermentables';
  }

  render() {
    let fermentable;
    if (this.props.fermentableId) {
      fermentable = this.api.getFermentableById(this.props.fermentableId);
    }

    const dataCells = LibraryUtil.getLibraryDataCellsInOrder(fermentable);
    const fermentableDescription = LibraryUtil.getLibraryDataAttributeByKey(dataCells, 'description');
    const fermentableManufacturer = LibraryUtil.getLibraryDataAttributeByKey(dataCells, 'manufacturer');
    const fermentableColor = LibraryUtil.getLibraryDataAttributeByKey(dataCells, 'color');
    const fermentablePpg = LibraryUtil.getLibraryDataAttributeByKey(dataCells, 'ppg');
    const fermentableCountry = LibraryUtil.getLibraryDataAttributeByKey(dataCells, 'country');

    const verb = this.props.fermentableId ? 'Update' : 'Add New';

    return (
      <ToolContainer>
        <Toolbelt underTopMenu="true">
        </Toolbelt>
        <section className="tool">
          <div className="main">
            <h1>{verb} Fermentable</h1>
            <form className="detail-form">
              <input id="fermentable-description" label="Description" placeholder="Description" defaultValue={fermentableDescription && fermentableDescription.value} />
              <input id="fermentable-manufacturer" label="Manufacturer/Maltster" placeholder="Manufacturer/Maltster" defaultValue={fermentableManufacturer && fermentableManufacturer.value} />
              <input id="fermentable-color" label="Color" placeholder="Color" defaultValue={fermentableColor && fermentableColor.value} />
              <input id="fermentable-ppg" label="PPG" placeholder="PPG" defaultValue={fermentablePpg && fermentablePpg.value} />
              <input id="fermentable-country" label="Country" placeholder="Country" defaultValue={fermentableCountry && fermentableCountry.value} />
              <StandardButton alternate label={fermentable ? "Update" : "Add" } onClick={this.processSubmission} />
            </form>
          </div>
        </section>
      </ToolContainer>
    );
  }
}

export class FermentablesLibrary extends React.PureComponent {
  constructor(props) {
    super(props);

    this.api = new FoamFactoryLocalStorageAPI();
  }

  render() {
    let fermentableObjects = this.api.getAllFermentables();

    let renderedFermentables = [];
    for (let i = 0; i < fermentableObjects.length; i++) {
      let fermentableObject = fermentableObjects[i];
      const dataInOrder = LibraryUtil.getLibraryDataCellsInOrder(fermentableObject);
      const fermentableDescription  = LibraryUtil.getLibraryDataAttributeByKey(dataInOrder, 'description').value;
      const fermentableManufacturer = LibraryUtil.getLibraryDataAttributeByKey(dataInOrder, 'manufacturer').value;
      const fermentableCountry = LibraryUtil.getLibraryDataAttributeByKey(dataInOrder, 'country').value;
      let fermentableKey = `${fermentableDescription}-${fermentableManufacturer}-${fermentableCountry}`;

      const dataCells = _.map(dataInOrder, (dataCell) => {
        return (
          <div key={`${fermentableKey}-${dataCell.key}`}className="data-cell">{dataCell.value}</div>
        );
      });

      renderedFermentables.push((
        <div
          key={fermentableKey}
          className="fermentables-table-row">
          {dataCells}
          <div key={`${fermentableKey}-actionIcons`}className="data-cell">
            <IconButton
              icon={`Pencil`}
              size={`24px`}
              onClick={() => {
                window.location = `/library/fermentables/update/${fermentableObject.id}`;
              }} />
          </div>
        </div>
      ));
    }

    return (
      <ToolContainer>
        <Toolbelt underTopMenu="true">
          <Tool icon={<Icons.Add size="34px" />} name="add-fermentable" label="Add Fermentable" location={`/library/fermentables/add`} />
        </Toolbelt>
        <section className="tool">
          <div className="main">
            <h1>Fermentables</h1>
            <div className="fermentables-table">
              <div className="fermentables-table-row">
                <div className="header-cell">Description</div>
                <div className="header-cell">Maltster/Manufacturer</div>
                <div className="header-cell">Color</div>
                <div className="header-cell">PPG</div>
                <div className="header-cell">Country of Origin</div>
                <div className="header-cell">&nbsp;</div>
              </div>
              {renderedFermentables}
            </div>
          </div>
        </section>
      </ToolContainer>
    );
  }
}
