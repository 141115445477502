import React from 'react';

import { Icons, Tool, Toolbelt } from '@foamfactory/aegir';

export class Dashboard extends React.PureComponent {
  render() {
    let divStyle = {
      width: "5em"
    };

    return (
      <React.Fragment>
        <div className="tool-container">
          <Toolbelt>
            <Tool icon={<Icons.RecipeBook size="35px" />} name="recipe-book" label="Recipe Book" onClick={() => { console.log("RecipeBook selected") }} />
            <Tool icon={<Icons.Inventory size="35px" />} name="inventory" label="Inventory" />
            <Tool icon={<Icons.Logbook size="35px" />} name="logbook" label="Logbook" location={`/tools/logbook`} />
            <Tool icon={<Icons.Quartermaster size="35px" />} name="quartermaster" label="Quartermaster" />
            <Tool icon={<Icons.GroupsGuilds size="35px" />} name="groups-and-guilds" label="Groups and Guilds" />
            <Tool icon={<Icons.RatingSheets size="35px" />} name="rating-sheets" label="Rating Sheets" />
          </Toolbelt>
          <section className="tool">
            <div style={divStyle}>
              <h1>Dashboard</h1>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}
