import { FoamFactoryAPI } from './FoamFactoryAPI';
import { LibraryUtil } from '../util/LibraryUtil';
import { SessionUtil } from '../util/SessionUtil';


const API_PROTOCOL_DEFAULT = 'http';
const API_HOST_DEFAULT = '0.0.0.0';
const API_PORT_DEFAULT = 80;
const API_VERSION_DEFAULT = 0;
const API_REQUIRED_VERSION = "0.1.4";

export class FoamFactoryRemoteAPI extends FoamFactoryAPI {
  constructor(api_key, protocol=API_PROTOCOL_DEFAULT, host=API_HOST_DEFAULT, port=API_PORT_DEFAULT, version=API_VERSION_DEFAULT) {
    super();

    console.log(`Attemping to initialize API: ${protocol}://${host}:${port}/v${version}`);

    this._baseUrl = `${protocol}://${host}:${port}/v${version}`;
    this._apiKey = api_key;
  }

  getBaseUrl() {
    return this._baseUrl;
  }

  initialize() {
    let self = this;

    // Verify the version is correct
    let version_ep_path = `${this._baseUrl}/version`;

    const fetchInit = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      mode: 'cors'
    };

    return fetch(version_ep_path, fetchInit)
      .then((response) => {
        return response.json();
      })
      .then((json_data) => {
        // TODO_jwir3: Check version in a more robust way
        if (json_data.version_code < 10004) {
          throw {
            exception: new Error(`Version of API is reported as ${json_data.version_name} but at least v${API_REQUIRED_VERSION} is required`),
            baseUrl: this._baseUrl
          };
        }
        return self;
      })
      .catch((err) => {
        return Promise.reject({
          exception: err,
          baseUrl: this._baseUrl
        });
      });
  }

  getUserById(userId) {
    return new Promise((resolve, reject) => {
      const fetchInit = {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${this.getAuthenticatedUser().auth_token}`,
          "Accept": "application/json"
        }
      };

      let user_path = `${this._baseUrl}/users/${userId}`;
      fetch(user_path, fetchInit)
      .then((response) => {
        return response.json();
      })
      .then((user_record) => {
        resolve(user_record);
      })
      .catch((e) => {
        reject(e);
      });
    });
  }

  login(username, password) {
    console.log("Logging in with username: " + username);

    let api_key = this._apiKey;

    return new Promise((resolve, reject) => {
      const fetchInit = {
        method: 'POST',
        headers: {
          "X-Api-Key": api_key,
          "Authorization": LibraryUtil.getLoginAuthorizationHeader(username, password),
          "Accept": 'application/json'
        }
      };

      let login_path = `${this._baseUrl}/login`;
      fetch(login_path, fetchInit)
        .then((response) => {
          if (response.status !== 200) {
            return reject(`Dank farrik! It looks like that username/password combination didn't work.`);
          }

          return response.json();
        })
        .then((user_record) => {
          console.log("User record initialized: ");
          console.log(user_record);

          let auth_user = {
            id: user_record.id,
            auth_token: user_record.auth_token
          };

          SessionUtil.setAuthenticatedUser(auth_user);

          resolve(user_record);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  changePassword(password, passwordConfirmation) {
    const auth_token = this.getAuthenticatedUser().auth_token;
    let body = {
      "new_password": password,
      "new_password_confirmation": passwordConfirmation
    };

    const fetchConfig = {
      method: 'PATCH',
      headers: {
        "Authorization": `Bearer ${auth_token}`,
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    }

    let path = `${this._baseUrl}/password/change`;
    return fetch(path, fetchConfig)
      .then((response) => {
        return response.json();
      });
  }

  updateAvatarForCurrentUser(avatarUrl) {
    let body = {
      "avatar": {
        "data_uri": avatarUrl
      },
    };

    const auth_token = this.getAuthenticatedUser().auth_token;

    const fetchConfig = {
      method: "PATCH",
      headers: {
        "Authorization": `Bearer ${auth_token}`,
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    };

    let path = `${this._baseUrl}/users/avatar/update`;
    return fetch(path, fetchConfig)
      .then((response) => {
        return response.json();
      });
  }

  getVersion() {
    const fetchConfig = {
      method: "GET",
      headers: {
        "Accept": "application/json"
      },
    };
    let path = `${this._baseUrl}/version`;
    return fetch(path, fetchConfig)
      .then((response) => {
        return response.json();
      });
  }

  getAuthenticatedUser() {
    return SessionUtil.getAuthenticatedUser();
  }
}
