import _ from 'lodash';

import { FoamFactoryAPI } from './FoamFactoryAPI';
import { LibraryUtil } from '../util/LibraryUtil';

import baseData from './dataRepo-seeds.json';

const DATA_REPO_ID = 'foamfactory-dataRepo';

export class FoamFactoryLocalStorageAPI extends FoamFactoryAPI {
  constructor(forceReset=false) {
    super();

    if (!window.localStorage) {
      throw new Error('Unable to initialize localStorage!');
    }

    if (forceReset) {
      window.localStorage.clear();
    }

    const data = window.localStorage.getItem(DATA_REPO_ID);
    if (!data) {
      window.localStorage.setItem(DATA_REPO_ID, JSON.stringify(baseData));
    }
  }

  getVersion() {
    return JSON.parse(window.localStorage.getItem(DATA_REPO_ID)).version;
  }

  // Users
  getUsers() {
    return JSON.parse(window.localStorage.getItem(DATA_REPO_ID)).users;
  }

  getAuthenticatedUser() {
    let userId = this._getRootItem('authenticatedUserId');
    return this.getUserById(userId);
  }

  login(username, password) {
    return new Promise((resolve, reject) => {
      _.each(this.getUsers(), (user) => {
        if (user.username === username && user.password === password) {
          this._putRootItem('authenticatedUserId', user.id);
          resolve(user.id);
          return false;
        }
      });

      if (!this._getRootItem('authenticatedUserId')) {
        reject(`Dank farrik! It looks like that username/password combination didn't work.`);
      }
    });
  }

  logout() {
    this._removeRootItem('authenticatedUserId');
  }

  getUserById(id) {
    let foundUser = null;
    _.each(this.getUsers(), (user) => {
      if (user.id === id) {
        foundUser = user;
        return false;
      }
    });

    return foundUser;
  }

  // Recipes
  getAllRecipes() {
    return this._getRootItem('recipes') || [];
  }

  getRecipeById(id) {
    let allRecipes = this.getAllRecipes();

    let foundRecipe = null;
    _.each(allRecipes, (recipe) => {
      if (recipe.id && id && parseInt(recipe.id) === parseInt(id)) {
        foundRecipe = recipe;
        return false;
      }
    });

    return foundRecipe;
  }

  // Fermentables
  getAllFermentables() {
    return this._getRootItem('fermentables') || [];
  }

  getFermentableById(id) {
    return this._getItemByNumericalId(id, this.getAllFermentables());
  }

  // A fermentable "key" is the concatenation of name, manufacturer, and
  // country, separated by dashes.
  getFermentableByKey(key) {
    const fermentables = this.getAllFermentables();
    const idx = _.findIndex(fermentables, (nextFermentable) => {
      return key === `${nextFermentable.name}-${nextFermentable.manufacturer}-${nextFermentable.country}`;
    });

    if (idx >= 0) {
      return fermentables[idx];
    }

    return null;
  }

  addFermentable(fermentable) {
    const fermentables = this.getAllFermentables();
    const id = this._findUnusedId(fermentables);
    fermentables.push({
      id: id,
      ...fermentable
    });

    this._putRootItem('fermentables', fermentables);
  }

  updateFermentable(id, fermentable) {
    const fermentables = this.getAllFermentables();
    const index = _.findIndex(fermentables, (nextFermentable) => {
      return Number(nextFermentable.id) === Number(id);
    });

    if (index < 0) {
      this.addFermentable(fermentable);
    } else {
      fermentables[index] = {
        id: id,
        ...fermentable
      };
    }

    this._putRootItem('fermentables', fermentables);
  }

  // Hops
  getAllHops() {
    return this._getRootItem('hops') || [];
  }

  getHopById(id) {
    return this._getItemByNumericalId(id, this.getAllHops());
  }

  getHopByKey(key) {
    const hops = this.getAllHops();
    const idx = _.findIndex(hops, (nextHop) => {
      const nextHopKey
        = LibraryUtil.getCompositeKeyForLibraryObject(nextHop,
                                                      ['description',
                                                       'manufacturer',
                                                       'alphaAcid']);
      return key === nextHopKey;
    });

    if (idx >= 0) {
      return hops[idx];
    }

    return null;
  }

  addHop(hop) {
    const hops = this.getAllHops();
    const id = this._findUnusedId(hops);
    hops.push({
      id: id,
      ...hop
    });

    this._putRootItem('hops', hops);
  }

  updateHop(id, hop) {
    const hops = this.getAllHops();
    const index = _.findIndex(hops, (nextHop) => {
      return Number(nextHop.id) === Number(id);
    });

    if (index < 0) {
      this.addHop(hop);
    } else {
      hops[index] = {
        id: id,
        ...hop
      };
    }

    this._putRootItem('hops', hops);
  }

  // Yeasts
  getAllYeasts() {
    return this._getRootItem('yeasts');
  }

  getYeastById(id) {
    return this._getItemByNumericalId(id, this.getAllYeasts());
  }

  // Process Templates
  getAllProcesses() {
    return this._getRootItem('processes');
  }

  getProcessById(id) {
    return this._getItemByStringId(id, this.getAllProcesses());
  }

  getPhaseByProcessAndStageId(processId, stageId, phaseId) {
    const process = this.getProcessById(processId);

    if (!process) {
      return null;
    }

    const stage = this._getFirstHavingId(process.stages, stageId);
    if (!stage) {
      return null;
    }

    const phase = this._getFirstHavingId(stage.phases, phaseId);
    if (!phase) {
      return null;
    }

    return phase;
  }

  // Creates a new, empty recipe so that an id is generated.
  createNewEmptyRecipe() {
    let recipes = this.getAllRecipes();
    let suitableId = recipes.length + 1;
    let possibleRecipe = this.getRecipeById(suitableId);
    while (recipes && recipes.length > 0 && possibleRecipe) {
      suitableId++;
      possibleRecipe = this.getRecipeById(suitableId);
    }

    return {
      id: suitableId
    };
  }

  async saveRecipe(recipe) {
    let recipes = this.getAllRecipes();
    let index = _.findIndex(recipes, (nextRecipe) => {
      return nextRecipe.id === recipe.id;
    });

    if (index >= 0) {
      recipes[index] = recipe;
    } else {
      recipes.push(recipe);
    }

    this._putRootItem('recipes', recipes);
  }

  // Beer Styles
  getStyleById(id) {
    if (!id) {
      return null;
    }

    let beerCategory = id.split('-')[0];
    let beerStyle = id.split('-')[1];

    return this.getStyleByCategoryAndId(beerCategory, beerStyle);
  }

  getStyleByCategoryAndId(category, id) {
    const beerCategories = this._getRootItem('beerStyles');

    for (let i = 0; i < beerCategories.length; i++) {
      let beerCategory = beerCategories[i];
      if (beerCategory.category === category) {
        const beerStyles = beerCategory.styles;

        for (let j = 0; j < beerStyles.length; j++) {
          const beerStyle = beerStyles[j];
          const fullId = `${category}-${id}`;

          if (beerStyle.id === fullId) {
            return beerStyle;
          }
        }
      }
    }

    return null;
  }

  // Generic Ingredients
  getIngredientByTypeAndId (type, id) {
    switch (type.toLowerCase()) {
      case 'hop':
        return this.getHopById(id);
      case 'yeast':
        return this.getYeastById(id);
      case 'fermentable':
      default:
        return this.getFermentableById(id);
    }
  }

  getAllIngredientsOfType(type) {
    switch (type) {
      case 'hops':
        return this.getAllHops();
      case 'fermentables':
        return this.getAllFermentables();
      case 'yeasts':
        return this.getAllYeasts();
      default:
        return [];
    }
  }

  getIngredientsInRecipeByStagePhaseAndType(recipe, stageId, phaseId, type) {
    if (!recipe) {
      return [];
    }

    for (let ingredientSetIdx in recipe.ingredientSets) {
      const ingredientSet = recipe.ingredientSets[ingredientSetIdx];
      if (ingredientSet.stageId === stageId
          && ingredientSet.phaseId === phaseId) {
        return _.filter(ingredientSet.ingredients, (ingredient) => {
          return ingredient.type === type;
        });
      }
    }

    return [];
  }

  getIngredientsForProcessAndPhases(processId, phases) {
    const process = this.getProcessById(processId);
    let ingredients = [];

    for (let i = 0; i < process.stages.length; i++) {
      const nextStage = process.stages[i];

      for (let j = 0; j < nextStage.phases.length; j++) {
        const nextPhase = nextStage.phases[j];
        let weights = [];
        _.each(nextPhase.ingredientTemplate, (templateItem) => {
          if (!templateItem.noIdContribution) {
            const weightObj = {};
            weightObj[templateItem.id] = templateItem.weight;
            weights.push(weightObj);
          }
        });

        if (!!nextPhase.ingredientTypes && phases.indexOf(nextPhase.id) >= 0) {
          for (let k = 0; k < nextPhase.ingredientTypes.length; k++) {
            const nextIngredientType = nextPhase.ingredientTypes[k];

            ingredients.push({
              label: nextIngredientType.toUpperCase(),
              weightTemplate: weights,
              options: this.getAllIngredientsOfType(nextIngredientType)
            });
          }
        }
      }
    }

    return ingredients;
  }

  _findUnusedId(list) {
    let idx = 0;

    /* eslint no-loop-func: "off" */
    while (_.findIndex(list, (nextItem) => {
      return nextItem.id === idx;
    }) >= 0) {
      idx++;
    }

    return idx;
  }

  _removeRootItem(itemId) {
    return new Promise((resolve, reject) => {
      let ls = JSON.parse(window.localStorage.getItem(DATA_REPO_ID));
      delete ls[itemId];

      try {
        console.log("Setting item: ", ls);
        window.localStorage.setItem(DATA_REPO_ID, JSON.stringify(ls));
      } catch (e) {
        reject(e);
      }

      resolve();
    });
  }

  _putRootItem(itemId, value) {
    let ls = JSON.parse(window.localStorage.getItem(DATA_REPO_ID));
    ls[itemId] = value;

    window.localStorage.setItem(DATA_REPO_ID, JSON.stringify(ls));
  }

  _getRootItem(itemId) {
    // First, pull from localStorage
    let rootItem = JSON.parse(window.localStorage.getItem(DATA_REPO_ID))[itemId];

    // Next, try from seeds.
    if (!rootItem) {
      console.log("Grabbing from seeds...");
      if (baseData[itemId]) {
        rootItem = baseData[itemId];
        this._putRootItem(itemId, rootItem);
      }
    }

    return rootItem;
  }

  _getItemByNumericalId(id, list) {

    let idx = _.findIndex(list, (nextItem) => {
      return Number(nextItem.id) === Number(id);
    });

    if (idx < 0) {
      return null;
    }

    return list[idx];
  }

  _getItemByStringId(id, list) {
    let idx = _.findIndex(list, (nextItem) => {
      return nextItem.id === id;
    });

    if (idx < 0) {
      return null;
    }

    return list[idx];
  }

  _getFirstHavingId(collection, id, idName='id') {
    return _.first(_.filter(collection, (nextItem) => {
      return nextItem[idName] === id;
    }));
  }
}
