import { ReactSession } from 'react-client-session';

export class SessionUtil {
    static setAuthenticatedUser(auth_user) {
        ReactSession.setStoreType('localStorage');
        ReactSession.set('authenticatedUser', JSON.stringify(auth_user));
    }

    static getAuthenticatedUser() {
        ReactSession.setStoreType("localStorage");
        let auth_user = ReactSession.get("authenticatedUser");
        if (auth_user) {
          return JSON.parse(auth_user);
        }

        return null;
      }
}