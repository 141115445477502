import React from 'react';
import $ from 'jquery';

import {
        Icons,
        IconButton,
        StandardButton,
        Tool,
        Toolbelt,
        ToolContainer
       } from '@foamfactory/aegir';


       import './HopsLibrary.scss';
import { FoamFactoryAPIManager } from '../../api/FoamFactoryAPIManager';

export class HopsLibraryDetail extends React.PureComponent {
  constructor(props) {
    super(props);

    this.api = FoamFactoryAPIManager.getApi();

    this.processAddHop = this.processAddHop.bind(this);
    this.processUpdateHop = this.processUpdateHop.bind(this);
    this.getHopData = this.getHopData.bind(this);

    this.state = {};
  }

  componentDidMount() {
    if (this.props.hopId) {
      this.api.getHop(this.props.hopId)
        .then((result) => {
          this.setState({
            'varietal': result.varietal,
            'alpha_acid_range': {
              'min': result.alpha_acid_range.min,
              'max': result.alpha_acid_range.max
            },
            'manufacturer': result.manufacturer
          });
        });
    }
  }

  getHopData() {
    const varietal = $(`#hop-varietal`).val();
    const manufacturer = $(`#hop-manufacturer`).val();
    const alphaAcid = $(`#hop-alphaAcid`).val();
    let min = '';
    let max = '';

    if (alphaAcid.indexOf("-") !== -1) {
      let aaString = alphaAcid.replace(/\s/g, '');
      let aaStringSplits = aaString.split("-");
      min = aaStringSplits[0];
      max = aaStringSplits[1];
    } else {
      min = alphaAcid;
      max = alphaAcid;
    }

    return {
      'varietal': varietal,
      'manufacturer': manufacturer,
      'alpha_acid_range': {
        'min': min,
        'max': max
      }
    };
  }

  processUpdateHop(event) {
    event.preventDefault();

    let hop = this.getHopData();

    this.api.updateHop(this.props.hopId, hop)
      .then((result) => {
        window.location = '/library/hops';
      });
  }

  processAddHop(event) {
    event.preventDefault();

    let hop = this.getHopData();

    this.api.addHop(hop)
      .then((result) => {
        window.location = '/library/hops';
      });
  }

  render() {
    const verb = this.props.hopId ? 'Update' : 'Add New';

    return (
      <ToolContainer>
        <Toolbelt underTopMenu="true">
        </Toolbelt>
        <section className="tool">
          <div className="main">
            <h1>{verb} Hop</h1>
            <form className="detail-form">
              <input id="hop-varietal" label="Varietal" placeholder="Varietal" defaultValue={this.state.varietal} />
              <input id="hop-manufacturer" label="Manufacturer/Maltster" placeholder="Manufacturer/Maltster" defaultValue={this.state.manufacturer} />
              <input id="hop-alphaAcid" label="Alpha Acid" placeholder="Alpha Acid" defaultValue={this.state.alpha_acid_range && `${this.state.alpha_acid_range.min} - ${this.state.alpha_acid_range.max}`} />
              <StandardButton alternate label={verb} onClick={this.props.hopId ? this.processUpdateHop : this.processAddHop} />
            </form>
          </div>
        </section>
      </ToolContainer>
    );
  }
}

export class HopsLibrary extends React.PureComponent {
  constructor(props) {
    super(props);

    this.api = FoamFactoryAPIManager.getApi();

    this.state = {};
  }

  componentDidMount() {
    this.api.getAllHops()
      .then((result) => {
        // We'll actually set the state here now.
        this.setState({
          hops: result.hops
        });
      });
  }

  render() {
    let renderedHops = [];
    if (this.state.hops) {
      for (let i = 0; i < this.state.hops.length; i++) {
        let nextHop = this.state.hops[i];
        let hopKey = `${nextHop.varietal}-${nextHop.manufacturer}-${nextHop.origin}`;

        renderedHops.push((
          <div
            key={hopKey}
            className="hops-table-row">
            <div className="data-cell">
              {nextHop.varietal}
            </div>
            <div className="data-cell">
              {nextHop.manufacturer}
            </div>
            <div className="data-cell">
              {`${nextHop.min_alpha_acid}-${nextHop.max_alpha_acid}`}
            </div>
            <div key={`${hopKey}-actionIcons`} className="data-cell">
              <IconButton
                icon={`Pencil`}
                size={`24px`}
                onClick={() => {
                  window.location = `/library/hops/update/${nextHop.id}`;
                }} />
            </div>
          </div>
        ));
      }
    }

    return (
      <ToolContainer>
        <Toolbelt underTopMenu="true">
          <Tool icon={<Icons.Add size="34px" />} name="add-hop" label="Add Hop" location={`/library/hops/add`} />
        </Toolbelt>
        <section className="tool">
          <div className="main">
            <h1>Hops</h1>
            <div className="hops-table">
              <div className="hops-table-row">
                <div className="header-cell">Varietal</div>
                <div className="header-cell">Manufacturer</div>
                <div className="header-cell">Alpha Acid</div>
                <div className="header-cell">&nbsp;</div>
              </div>
              {renderedHops}
            </div>
          </div>
        </section>
      </ToolContainer>
    );
  }
}
