import React from 'react';

import { FoamFactoryAPIManager } from '../../api/FoamFactoryAPIManager';
import { FrontmatterPageHeader, LeftOrTopBackgroundLayout, LockUp, LoginForm, RandomUtil } from '@foamfactory/aegir';

import './LoginView.scss';

export class LoginView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.submitLoginForm = this.submitLoginForm.bind(this);

    this.rightSideId = RandomUtil.getUniqueDOMID();

    // this.api = FoamFactoryAPIManager.getApi();

    this.state = {
      lockUpKey: RandomUtil.getUniqueDOMID(),
      api: FoamFactoryAPIManager.getApi()
    };
  }

  submitLoginForm(username, password) {
    return this.state.api
      .then((initializedApi) => {
        return initializedApi.login(username, password)
      })
      .then((user_id) => {
        window.location = '/dashboard';
      });
  }

  // componentDidMount() {
  //   let rightSideWidth
  //     = document.getElementById(this.rightSideId).getBoundingClientRect().width;
  //
  //   if (!this.state.lockUpWidth) {
  //     this.setState({
  //       lockUpWidth: rightSideWidth * .40,
  //       lockUpKey: RandomUtil.getUniqueDOMID()
  //     });
  //   }
  // }

  render() {
    return (
      <LeftOrTopBackgroundLayout backgroundImage="AbstractBarley">
        <div id={this.rightSideId} className="login-form-flex-container">
          <LockUp key={this.state.lockUpKey} width={'600px'} />
          <div className="greeting-and-form">
            <FrontmatterPageHeader title={"Hello There!"} />
            <LoginForm onLogin={this.submitLoginForm} />
          </div>
        </div>
      </LeftOrTopBackgroundLayout>
    );
  }
}
