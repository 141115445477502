import React from 'react';
import { Grid } from 'grommet';

import {
  AvatarUploader,
  CondensedSelectionField,
  HexagonalAvatar,
  IconAndEditableField,
  Icons,
  ModalPopup,
  PasswordAndConfirmationSet,
  SettingsSection,
  Tool,
  Toolbelt,
  ToolContainer
} from '@foamfactory/aegir';

import { ErrorView } from './ErrorView';
import { FoamFactoryAPIManager } from '../../api/FoamFactoryAPIManager';
import { SessionUtil } from '../../util/SessionUtil';

import defaultAvatar from '../../assets/images/default_user_avatar.png';

import './UserProfile.scss';

export class UserProfile extends React.PureComponent {
  constructor(props) {
    super(props);

    this.avatarOnClick = this.avatarOnClick.bind(this);

    this.hexAvatarRef = React.createRef();
    this.avatarModalRef = React.createRef();
    this.avatarUploaderRef = React.createRef();

    this.onAvatarFileChanged = this.onAvatarFileChanged.bind(this);
    this.onModalConfirmed = this.onModalConfirmed.bind(this);

    this.changePassword = this.changePassword.bind(this);

    this.newAvatarFile = null;

    this.apiPromise = FoamFactoryAPIManager.getApi();
    this.state = {
      user: {
        avatar: {
          dataUri: defaultAvatar
        }
      },
      authenticatedUser: SessionUtil.getAuthenticatedUser(),
      showAvatarModal: false,
    };
  }

  componentDidMount() {
    let self = this;

    // Check to see if the API is working by pulling the version, and if not, render an error page
    if (!this.state.error) {
      this.apiPromise
        .then((initializedApi) => {
          return initializedApi.getUserById(self.state.authenticatedUser.id);
        })
        .then((user_record) => {
          self.setState({
            user: user_record
          });
        })
        .catch((err) => {
          this.setState({
            error: err
          })
        });
    }
  }

  avatarOnClick() {
    if (this.avatarModalRef && this.avatarModalRef.current) {
      this.avatarModalRef.current.openModal();

      if (this.hexAvatarRef && this.hexAvatarRef.current) {
        this.hexAvatarRef.current.onMouseLeave();
      }
    }
  }

  changePassword(password, confirmation) {
    return this.api.changePassword(password, confirmation)
      .then(() => {
        return this.api.logout();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  onModalConfirmed() {
    let self = this;

    let fileReader = new FileReader();
    fileReader.addEventListener("load", function () {
      // convert image file to base64 string
      let dataUri = fileReader.result;

      // Upload avatar to avatar service
      self.apiPromise
      .then((initializedApi) => {
        return initializedApi.updateAvatarForCurrentUser(dataUri);
      })
      .then((response) => {
        let updated_user =  {
          user: {
            ...self.state.user,
            avatar: {
              ...self.state.user.avatar,
              data_uri: dataUri
            }
          }
        };

        self.newAvatarFile = null;
        self.setState(updated_user);
      })
      .catch((e) => {
        console.error("Unable to update avatar due to server response:");
        console.error(e);
        self.newAvatarFile = null;
      });
    });

      fileReader.readAsDataURL(self.newAvatarFile);
  }

  onAvatarFileChanged(file) {
    this.newAvatarFile = file;
  }

  render() {
    if (this.state.error) {
      // We encountered an error with the API, so we need to report this
      // console.log("Error encountered: ", this.state.error);
      return (
        <ErrorView
          title={"API Initialization Error"}
          description={<span><p>There was an issue initializing the API. We were unable to connect to <strong>{this.state.error.baseUrl}</strong>.</p><p>This is likely a service-level issue with the FoamFactory API, meaning that this will be resolved when our engineers are able to restore service. Please note we're working on the issue and will have it fixed as quickly as possible.</p></span>}
          icon={Icons.BarrelWithBrokenTap}
        />
      );
    }

    let firstFieldStyle = {
      gridArea: 'first-field'
    };

    let secondFieldStyle = {
      gridArea: 'second-field'
    };

    let avatarStyle = {
      gridArea: 'avatar',
      marginLeft: '7em'
    };

    let nonFirstSectionStyle = {
      marginTop: '12em'
    };

    let volumeOptions = [
        {
          label: 'Quarts'
        },
        {
          label: 'Gallons'
        },
        {
          label: 'Liters'
        },
        {
          label: 'Barrels'
        },
        {
          label: 'Hectoliters'
        }
      ];

      let tempOptions = [
        {
          label: '°F'
        },
        {
          label: '°C'
        },
        {
          label: 'K'
        }
      ];

      let colorOptions = [
        {
          label: "SRM"
        },
        {
          label: "EBC"
        }
      ];

    return (
      <ToolContainer>
        <Toolbelt underTopMenu="true">
          <Tool
            location={'#settings-section-personal-info'}
            icon={<Icons.IDCard size="34px" />}
            name="personal-info"
            label="Personal Info" />
          <Tool
            location={'#settings-section-measurement-settings'}
            icon={<Icons.Ruler size="34px" />}
            name="measurement-settings"
            label="Measurement Settings" />
          <Tool
            location={'#settings-section-security-settings'}
            icon={<Icons.Shield size="34px" />}
            name="security-settings"
            label="Security" />
        </Toolbelt>
        <section className="tool">
          <div className="main">
            <h1>Settings</h1>
            <SettingsSection
              title="Personal Info"
              description="Basic information, like your name and email address that is used across FoamFactory services and may be shown to other users.">
              <div className="settings-personal-info">
                <Grid
                  rows={['45px', '45px']}
                  columns={['medium', 'small']}
                  gap="small"
                  align="start"
                  areas={[
                    { name: 'first-field', start: [0, 0], end: [0, 0] },
                    { name: 'second-field', start: [0, 1], end: [0, 1] },
                    { name: 'avatar', start: [1, 0], end: [1, 1] }
                  ]}
                >
                  <IconAndEditableField
                    id="editable-name"
                    content={"Scott Johnson"}
                    style={firstFieldStyle}
                    icon={(<Icons.Person className="icon" fill="#000000" size="45px" />)}
                  />
                  <IconAndEditableField
                    id="editable-email"
                    content={"jaywir3@gmail.com"}
                    style={secondFieldStyle}
                    icon={(<Icons.Mail className="icon" fill="#000000" size="45px" />)}
                  />
                  <HexagonalAvatar
                    id={"editable-avatar"}
                    ref={this.hexAvatarRef}
                    style={avatarStyle}
                    modifiable="true"
                    onClick={this.avatarOnClick}
                    imageUrl={(this.state.user.avatar && this.state.user.avatar.data_uri) || process.env.PUBLIC_URL + "/assets/images/default_user_avatar.png"} />
                  <ModalPopup
                    ref={this.avatarModalRef}
                    actionButton={{
                      title: "Confirm",
                      shouldClose: true,
                      onClick: () => { this.onModalConfirmed() }
                    }}
                    cancelButton={{
                      title: "Cancel",
                      shouldClose: true
                    }}
                    title="Change Avatar">
                    <p>Want to change the mask you’re wearing today? No problem! Just upload the image file you want to use and we’ll make sure to take care of the rest. Currently, square images work best and FoamFactory only accepts PNG or JPG file formats.</p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "auto auto",
                        width: "40%"
                      }}>
                      <AvatarUploader
                        ref={this.avatarUploaderRef}
                        avatar={this.state.avatarFile}
                        onChange={this.onAvatarFileChanged}
                      />
                    </div>
                  </ModalPopup>
                </Grid>
              </div>
            </SettingsSection>
            <SettingsSection
              style={nonFirstSectionStyle}
              title="Measurement Settings"
              description="Settings for measurement scales, such as the units in which volume and temperature measurements are made.">
              <div className="measurement-settings-layout">
                <CondensedSelectionField title="Volume Units" options={volumeOptions} />
                <CondensedSelectionField title="Temperature Units" options={tempOptions} />
                <CondensedSelectionField title="Color Method" options={colorOptions} />
              </div>
            </SettingsSection>
            <SettingsSection
              style={nonFirstSectionStyle}
              title="Security Settings"
              description="Settings related to the security of your account, such as passwords and cryptographic keys.">
              <PasswordAndConfirmationSet
                label="Change Password"
                description="Changing your password will log you out of FoamFactory"
                passwordChangeApi={this.changePassword}
                />
            </SettingsSection>
          </div>
        </section>
      </ToolContainer>
    );
  }
}
