import React from 'react';
// import * as keyevents from 'key-events';
// import * as Bitterness from 'bitterness';
import _ from 'lodash';
import getLogger from 'webpack-log';

import { BiNetworkChart } from 'react-icons/bi';

import { BeerStats,
         BlockBar,
         BrewTimeline,
         EditableRecipeTitle,
         InfoContainer,
         Ingredients,
         ProcessSection,
         RandomUtil,
         SelectDropdown,
         SrmUtil,
         Tool,
         Toolbelt
       } from '@foamfactory/aegir';

import { FoamFactoryLocalStorageAPI } from '../../api/FoamFactoryLocalStorageAPI';
import { MeasureUtil } from '../../util/MeasureUtil';
import { LibraryUtil } from '../../util/LibraryUtil';

import './RecipeDesigner.scss';

const logger = getLogger({ name: 'RecipeDesigner', level: 'debug' });

export class RecipeDesigner extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onTemplateSelected = this.onTemplateSelected.bind(this);
    this.onBeerStyleSelected = this.onBeerStyleSelected.bind(this);
    this.onRecipeTitleChanged = this.onRecipeTitleChanged.bind(this);
    this.onIngredientsChanged = this.onIngredientsChanged.bind(this);
    this.computeColor = this.computeColor.bind(this);
    this.onBatchSizeUnitChanged = this.onBatchSizeUnitChanged.bind(this);
    this.onBatchSizeAmountChanged = this.onBatchSizeAmountChanged.bind(this);
    this.computeYeastTemperature = this.computeYeastTemperature.bind(this);

    this.save = this.save.bind(this);

    this.api = new FoamFactoryLocalStorageAPI();

    const loadedRecipeId = this.props.recipeId;
    let recipe = null;
    if (loadedRecipeId) {
      recipe = this.api.getRecipeById(loadedRecipeId);

      if (!recipe) {
        // Let's redirect then to /tools/recipe-designer so that the user knows
        // that a new recipe is being created.
        window.location = "/tools/recipe-designer";
      }
    }

    let selectedTemplate = null;
    let beerType = null;

    if (!recipe) {
      recipe = this.api.createNewEmptyRecipe();
    } else {
      let selectedTemplateId = recipe.processTemplateId;
      selectedTemplate = this.api.getProcessById(selectedTemplateId);
      if (selectedTemplate) {
        beerType = selectedTemplate.type;
      }
    }

    this.state = {
      recipe: recipe,
      processTemplates: this.api.getAllProcesses(),
      authors: [this.api.getUserById(1)],
      selectedTemplate: selectedTemplate,
      beerType: beerType,

      // Store the key to the ingredients so we can re-generate it if need be.
      // Changing this forces a re-render.
      ingredientsKey: RandomUtil.getUniqueDOMID(),

      // Store the key to the BeerStats so we can re-generate it if need be.
      // Changing this forces a re-render.
      beerStatsKey: RandomUtil.getUniqueDOMID()
    };
  }

  componentDidMount() {
    this.setState((currentState) => {
      const gravity = this.computeGravity(currentState.recipe);
      return {
        srm: this.computeColor(currentState.recipe),
        ibu: null,
        originalGravity: gravity.og,
        finalGravity: gravity.fg,
        temp: this.computeYeastTemperature(currentState.recipe)
      };
    });
  }

  save() {
    // Activate the save mechanism on the API
    // XXX_jwir3: The client API should be set up as another, separate repo.
    // FoamFactoryAPI.
    return this.api.saveRecipe(this.state.recipe);
  }

  // XXX_jwir3: There are a number of things wrong with this, but we'll roll
  //            with it for now...
  computeYeastTemperature(recipe) {
    let minTemp, maxTemp;

    // for each of the yeasts in primary fermentation
    let primaryFermentationYeasts
      = this.api.getIngredientsInRecipeByStagePhaseAndType(recipe,
                                                           'primaryFermentation',
                                                           'primary-fermentation',
                                                           'yeast');

    for (let yeastIdx in primaryFermentationYeasts) {
      let nextYeast
        = LibraryUtil.hydrateIngredient(this.api,
                                        primaryFermentationYeasts[yeastIdx]);
      let temperatureRange
        = LibraryUtil.getLibraryObjectDataAttributeByKey(nextYeast,
                                                         'temperature').value;
      let tempRangeSplit = temperatureRange.split('-');
      if (minTemp) {
        minTemp = Math.max(Number(tempRangeSplit[0].trim()), minTemp);
      } else {
        minTemp = Number(tempRangeSplit[0].trim())
      }

      if (maxTemp) {
        maxTemp = Math.min(Number(tempRangeSplit[1].trim()), maxTemp);
      } else {
        maxTemp = Number(tempRangeSplit[1].trim())
      }
    }

    return {
      min: minTemp,
      max: maxTemp,

      // XXX_jwir3: We should change this to 'optimal' temperature, but it's
      //            kind of wonky if we have multiple yeasts.
      current: ((minTemp + maxTemp) / 2.0)
    };
  }

  computeGravity(recipe) {
    let og, fg;

    // For each mash ingredient, compute the PPG contribution
    const ingredients
      = this.api.getIngredientsInRecipeByStagePhaseAndType(recipe,
                                                           'brewing',
                                                           'mash',
                                                           'fermentable');
    let totalPoints = 0;
    for (let i = 0; i < ingredients.length; i++) {
      const nextIngredient = LibraryUtil.hydrateIngredient(this.api,
                                                           ingredients[i]);
      const ppg
        = LibraryUtil.getLibraryObjectDataAttributeByKey(nextIngredient,
                                                         'ppg');

      const amountInPounds = MeasureUtil.convertToPounds(nextIngredient.amount);
      totalPoints += amountInPounds * ppg.value;
    }

    totalPoints = totalPoints / 10.0;
    // NOTE: This now uses 75% efficiency, when it should really be asking
    //       the user.
    totalPoints = totalPoints * .75;
    og = ((totalPoints + 1000.0) / 1000.0).toFixed(3);

    const pfIngredients
      = this.api.getIngredientsInRecipeByStagePhaseAndType(recipe,
                                                           'primaryFermentation',
                                                           'primary-fermentation',
                                                           'yeast');
    logger.debug('pfIngredients: ', pfIngredients);

    if (pfIngredients.length > 0) {
      // XXX_jwir3: This uses only the first yeast ingredient. What happens
      //            if we have more than one yeast? What do our competitors
      //            do?
      const firstYeastIdx = _.findIndex(pfIngredients, (nextIngredient) => {
        return nextIngredient.type.toLowerCase() === 'yeast';
      });

      const firstYeast
        = LibraryUtil.hydrateIngredient(this.api, pfIngredients[firstYeastIdx]);
      logger.debug("First yeast: ", firstYeast);
      if (firstYeast) {
        const attenuation
          = LibraryUtil.getLibraryObjectDataAttributeByKey(firstYeast,
                                                           'attenuation');
        let totalOriginalPoints = (Number(og) - 1.000) * 1000;
        let finalPoints = (1.0 - (Number(attenuation.value)/100.0)) * totalOriginalPoints;
        fg = ((finalPoints + 1000.0) / 1000.0).toFixed(3);
      }
    }

    return {
      og: og,
      fg: fg
    };
  }

  computeColor(recipe) {
    // NOTE: This _only_ gets the color from the mash.
    let ingredients
      = this.api.getIngredientsInRecipeByStagePhaseAndType(recipe,
                                                           'brewing',
                                                           'mash',
                                                           'fermentable');


    let grainWeights = [];

    const batchSizeGallons
      = MeasureUtil.convertToGallons(recipe.batchSize);

    for (let i = 0; i < ingredients.length; i++) {
      const nextIngredient = LibraryUtil.hydrateIngredient(this.api,
                                                           ingredients[i]);
      const nextIngredientColor
        = LibraryUtil.getLibraryObjectDataAttributeByKey(nextIngredient,
                                                         'color');
      const nextIngredientWeightInLb
        = MeasureUtil.convertToPounds(nextIngredient.amount);
      grainWeights.push({
        dL: SrmUtil.getAverageInRange(nextIngredientColor.value),
        amountLb: nextIngredientWeightInLb
      });
    }

    // XXX_jwir3: This only uses mosher's formula. The others are implemented,
    //            but unused.
    const srmVal = SrmUtil.convertFromLovibondToSRM('mosher', batchSizeGallons,
                                                    grainWeights);
    return Math.max(0, Math.min(30, srmVal));
  }

  onBatchSizeAmountChanged(event) {
    const batchSizeAmount = Number(event.target.value);
    this.setState((currentState) => {
      let recipe = currentState.recipe;
      recipe.batchSize = {
        ...recipe.batchSize,
        amount: batchSizeAmount
      };

      const retVal = {
        recipe: recipe,
        srm: this.computeColor(recipe),

        // Change the beerStats key to force a re-render.
        beerStatsKey: RandomUtil.getUniqueDOMID()
      };

      return retVal;
    });
  }

  onBatchSizeUnitChanged(batchSizeUnit) {
    this.setState((currentState) => {
      let recipe = currentState.recipe;
      recipe.batchSize = {
        ...recipe.batchSize,
        unit: batchSizeUnit
      };

      return {
        recipe: recipe,
        srm: this.computeColor(recipe),

        // Change the beerStats key to force a re-render.
        beerStatsKey: RandomUtil.getUniqueDOMID()
      }
    });
  }

  onRecipeTitleChanged(title) {
    this.setState((previousState) => {
      let recipe = previousState.recipe;
      recipe.name = title;
      return {
        recipe: recipe
      };
    });
  }

  onBeerStyleSelected(beerStyle) {
    this.setState((previousState) => {
      let recipe = previousState.recipe;
      recipe.beerStyleId = beerStyle.id;
      const gravity = this.computeGravity(recipe);

      return {
        recipe: recipe,
        srm: this.computeColor(recipe),
        originalGravity: gravity.og,
        finalGravity: gravity.fg,

        // Change the beerStats key to force a re-render.
        beerStatsKey: RandomUtil.getUniqueDOMID()
      };
    });
  }

  onTemplateSelected(selectedTemplateId) {
    this.setState((currentState) => {
      let selectedTemplate = null;
      let type = null;

      for (let templateIdx in currentState.processTemplates) {
        let nextTemplate = currentState.processTemplates[templateIdx];
        if (nextTemplate.id === selectedTemplateId) {
          selectedTemplate = nextTemplate;
        }
      }

      if (selectedTemplate) {
        type = selectedTemplate.type;
      }

      let recipe = currentState.recipe;
      recipe.processTemplateId = selectedTemplate.id;

      return {
        recipe: recipe,
        selectedTemplate: selectedTemplate,
        beerType: type,

        // Change the ingredients key to force a re-render.
        ingredientsKey: RandomUtil.getUniqueDOMID(),

        // Change the beerStats key to force a re-render.
        beerStatsKey: RandomUtil.getUniqueDOMID()
      };
    });
  }

  onIngredientsChanged(ingredientSets) {
    this.setState((currentState) => {
      currentState.recipe = {
        ...currentState.recipe,
        ingredientSets: ingredientSets
      }

      const gravity = this.computeGravity(currentState.recipe);
      return {
        recipe: currentState.recipe,
        srm: this.computeColor(currentState.recipe),
        originalGravity: gravity.og,
        finalGravity: gravity.fg,
        temp: this.computeYeastTemperature(currentState.recipe),

        beerStatsKey: RandomUtil.getUniqueDOMID()
      };
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="tool-container">
          <Toolbelt underTopMenu="true">
            <Tool icon={<BiNetworkChart />} name="create-variant" label="Create Variant" />
          </Toolbelt>
          <section className="tool">
            <div className="main">
              <div className="top-recipe-info">
                <div className="title-and-batch-size">
                  <EditableRecipeTitle
                    id="editable-recipe-title"
                    content={this.state.recipe.name}
                    onTitleChanged={this.onRecipeTitleChanged}
                  />
                  <div className="batch-size">
                    <div className="batch-size-label">Batch Size</div>
                    <input
                      id="batch-size-value"
                      defaultValue={(this.state.recipe && this.state.recipe.batchSize && this.state.recipe.batchSize.amount) || 10}
                      type={`number`}
                      onChange={this.onBatchSizeAmountChanged}
                    />
                    <SelectDropdown
                      id="batch-size-unit"
                      onChange={this.onBatchSizeUnitChanged}
                      options={
                        [
                          {
                            value: "gallons",
                            label: "Gallons"
                          }
                        ]
                      }
                      value={this.state.recipe && this.state.recipe.batchSize && this.state.recipe.batchSize.unit }
                      />
                  </div>
                </div>
                <BlockBar
                  beerType={this.state.beerType}
                  beerStyleId={this.state.recipe && this.state.recipe.beerStyleId}
                  authors={this.state.authors}
                  onBeerStyleSelected={this.onBeerStyleSelected}
                />
              </div>
              <ProcessSection
                templateId={this.state.recipe && this.state.recipe.processTemplateId}
                templates={this.state.processTemplates}
                onTemplateSelected={this.onTemplateSelected}
              />

              <Ingredients
                key={this.state.ingredientsKey}
                processApi={this.api}
                ingredientsApi={this.api}
                processId={this.state.recipe && this.state.recipe.processTemplateId}
                ingredientSets={this.state.recipe && this.state.recipe.ingredientSets}
                onIngredientsChanged={this.onIngredientsChanged}
              />
              <InfoContainer title="Approximate Timeline">
                <BrewTimeline wholeDays={true} stages={this.state.selectedTemplate && this.state.selectedTemplate.stages} />
              </InfoContainer>
            </div>
            <BeerStats
              key={this.state.beerStatsKey}
              api={this.api}
              beerStyleId={this.state.recipe.beerStyleId}
              originalGravity={this.state.originalGravity}
              finalGravity={this.state.finalGravity}
              ibu={this.state.ibu}
              srm={this.state.srm}
              temp={this.state.temp}
            />
          </section>
        </div>
      </React.Fragment>
    );
  }
};
