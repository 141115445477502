import { FoamFactoryLocalStorageAPI } from './FoamFactoryLocalStorageAPI';
import { FoamFactoryRemoteAPI } from './FoamFactoryRemoteAPI';
import { ApiUtil } from '../util/ApiUtil';

export class FoamFactoryAPIManager {
  static _INSTANCE = null;

  static getApi() {
    return FoamFactoryAPIManager.getAPIForEnvironment(ApiUtil.getEnvironment());
  }

  static getAPIForEnvironment(environment) {
    if (!FoamFactoryAPIManager._INSTANCE) {
      switch (environment) {
        case 'local':
          FoamFactoryAPIManager._INSTANCE = new FoamFactoryRemoteAPI("fc411dc1b9bcc75f113951e574e243cca92fbddc", "http", "0.0.0.0", 1916, 0).initialize();
          break;
        case 'development':
          FoamFactoryAPIManager._INSTANCE = new FoamFactoryRemoteAPI("fc411dc1b9bcc75f113951e574e243cca92fbddc", "https", "dev.api.foamfactory.io", 443, 0).initialize();
          break;
        case 'staging':
          FoamFactoryAPIManager._INSTANCE = new FoamFactoryRemoteAPI("fc411dc1b9bcc75f113951e574e243cca92fbddc", "https", "stage.api.foamfactory.io", 443, 0).initialize();
          break;
        case 'production':
          FoamFactoryAPIManager._INSTANCE = new FoamFactoryRemoteAPI("0c4e97c2f7af608117e519d941f1d2fbc25fe46a", "https", "api.foamfactory.io", 443, 0).initialize();
          break;
        default:
          FoamFactoryAPIManager._INSTANCE = new FoamFactoryLocalStorageAPI().initialize();
          break;
      }
    }

    return FoamFactoryAPIManager._INSTANCE;
  }
}
