import React from 'react';

import { FrontmatterPageHeader, Icons } from '@foamfactory/aegir';

import { FoamFactoryAPIManager } from '../../api/FoamFactoryAPIManager';

import './LogoutView.scss';

import colors from '@foamfactory/aegir/src/assets/styles/_colors.scss';

export class LogoutView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      api: FoamFactoryAPIManager.getApi(),
      loggedOut: false
    };
  }

  componentDidMount() {
    let self = this;

    this.state.api
      .then((api) => {
        return api.logout()
      })
      .then(() => {
        self.setState({
          loggedOut: true
        });
      });
  }

  render() {
    console.log("Logged out? " + this.state.loggedOut);
    if (this.state.loggedOut) {
      window.location = '/';
    }

    return (
      <div className="logout-spinner">
        <Icons.Spinner size="70px" fill={colors.creamAle} />
        <FrontmatterPageHeader title="Logging out..." />
      </div>
    );
  }
}
