export class ApiUtil {
  static getEnvironment() {
    let environment = process.env.REACT_APP_FOAMFACTORY_ENVIRONMENT;
    if (!environment) {
      console.warn("No environment found in REACT_APP_FOAMFACTORY_ENVIRONMENT. Using 'development'.")
      environment = 'development';
    } else {
      console.log(`Using environment ${environment}`);
    }

    return environment;
  }
}
