export class BeerStylesDAO {
  constructor(source) {
    if (!source) {
      source = 'local';
    }

    this.source = source;
    this.unknownStyle = {
      name: 'Unknown Style'
    };
  }

  getStyle(category) {
    if (category) {
      let reference = category.reference;
      let style = category.style;
      let subStyle = category.subStyle;

      if (!reference || !style || !subStyle) {
        return this.unknownStyle;
      }

      let refCode = `${reference.toLowerCase()}-${style.toLowerCase()}${subStyle.toLowerCase()}`;
      let styles = this.getStyles();

      if (styles.hasOwnProperty(refCode)) {
        return styles[refCode]
      }
    }

    return this.unknownStyle;
  }

  getStyles() {
    if (this.source === 'local') {
      return {
        "bjcp-1c": {
          name: "Cream Ale",
          ogMin: 1.042,
          ogMax: 1.055,
          fgMin: 1.006,
          fgMax: 1.012,
          abvMin: 4.2,
          abvMax: 5.6,
          srmMin: 2.5,
          srmMax: 5.0,
          ibuMin: 8.0,
          ibuMax: 20.0
        },

        "bjcp-4b": {
          name: "Festbier",
          abvMin: 5.8,
          abvMax: 6.3,
          ibuMin: 18,
          ibuMax: 25,
          ogMin: 1.054,
          ogMax: 1.057,
          fgMin: 1.010,
          fgMax: 1.012,
          srmMin: 4,
          srmMax: 7
        },

        "bjcp-4c": {
          name: "Helles Bock",
          abvMin: 6.3,
          abvMax: 7.4,
          ibuMin: 23,
          ibuMax: 35,
          ogMin: 1.064,
          ogMax: 1.072,
          fgMin: 1.011,
          fgMax: 1.018,
          srmMin: 6,
          srmMax: 11
        },

        "bjcp-13b": {
          name: "British Brown Ale",
          ogMin: 1.040,
          ogMax: 1.052,
          fgMin: 1.008,
          fgMax: 1.013,
          abvMin: 4.2,
          abvMax: 5.4,
          srmMin: 12.0,
          srmMax: 22.0,
          ibuMin: 20,
          ibuMax: 30,
        },

        "bjcp-20a": {
          name: "American Porter",
          abvMin: 4.8,
          abvMax: 6.5,
          ibuMin: 25,
          ibuMax: 50,
          ogMin: 1.050,
          ogMax: 1.070,
          fgMin: 1.012,
          fgMax: 1.018,
          srmMin: 22,
          srmMax: 40
        },

        "bjcp-21a": {
          name: "American IPA",
          abvMin: 5.5,
          abvMax: 7.5,
          ibuMin: 40,
          ibuMax: 70,
          ogMin: 1.056,
          ogMax: 1.070,
          fgMin: 1.008,
          fgMax: 1.014,
          srmMin: 6,
          srmMax: 14
        },

        "bjcp-30b": {
          name: "Autumn Seasonal Beer",
          abvMin: 5.0,
          abvMax: 19.0,
          ibuMin: 0.0,
          ibuMax: 100.0,
          ogMin: 1.030,
          ogMax: 1.200,
          fgMin: 1.005,
          fgMax: 1.050,
          srmMin: 10.0,
          srmMax: 33.0
        }
      };
    }
  }
}
