import React from 'react';
import moment from 'moment';

import {
  AddLogEntryForm,
  DataTable,
  IconMessageWithTitle,
  Icons,
  ModalPopup,
  Tool,
  Toolbelt,
  ToolContainer
} from '@foamfactory/aegir';

import { FoamFactoryAPIManager } from '../../api/FoamFactoryAPIManager';

import colors from '../../assets/styles/_colors.scss';
import './Logbook.scss';

// XXX_jwir3: This should come from the process, once we have a way of reading it.
const EVENTS = [
  {
      "value": "planningCompleted",
      "label": "Planning Completed"
  },
  {
    "value": "mashCompleted",
    "label": "Mash Completed"
  },
  {
    "value": "spargeCompleted",
    "label": "Sparge Completed"
  },
  {
    "value": "boilStarted",
    "label": "Boil Started"
  },
  {
    "value": "boilAdditionMade",
    "label": "Addition Made to Boil"
  },
  {
    "value": "boilCompleted",
    "label": "Boil Completed"
  },
  {
    "value": "transferredToFermentor",
    "label": "Transferred to Fermentor"
  },
  {
    "value": "yeastPitched",
    "label": "Yeast Pitched"
  },
  {
    "value": "yeastHarvested",
    "label": "Yeast Harvested"
  },
  {
    "value": "racked",
    "label": "Racked"
  },
  {
    "value": "sampleTaken",
    "label": "Sample Taken"
  },
  {
    "value": "diacetylRestStarted",
    "label": "Diacetyl Rest Started"
  },
  {
    "value": "lageringStarted",
    "label": "Lagering Started"
  },
  {
    "value": "carbonationStarted",
    "label": "Carbonation Started"
  },
  {
    "value": "packaged",
    "label": "Packaged"
  },
  {
    "value": "other",
    "label": "Other"
  }
];

export class Logbook extends React.PureComponent {
  constructor(props) {
    super(props);

    this.api = FoamFactoryAPIManager.getApi();
    this.addLogEntryModalRef = React.createRef();

    this.onData = this.onData.bind(this);
    this.onBatchData = this.onBatchData.bind(this);
    this.submitNewLogEntry = this.submitNewLogEntry.bind(this);
    this.openAddLogEntryModal = this.openAddLogEntryModal.bind(this);
    this.onLogEntryFormChanged = this.onLogEntryFormChanged.bind(this);
    this.refreshLogEntries = this.refreshLogEntries.bind(this);

    this.state = {
      dataAcquired: false,
      events: EVENTS
    };
  }

  componentDidMount() {
    this.refreshLogEntries();

    this.api.getAllBatches()
      .then((result) => {
        // XXX_jwir3: This data is _paged_! We're going to have to figure out how we want to
        //            handle loading more pages.
        this.onBatchData(result);
      })
      .catch((e) => {
        console.error(`An exception occurred when attempting to retrieve all batches: `, e);
        this.onBatchData(null);
      });
  }


  refreshLogEntries() {
    // Clear data first
    this.onData(null);

    // XXX_jwir3: It would be cool if we could add a loading indicator here.
    this.api.getAllLogEntries()
    .then((result) => {
      this.onData(result);
    })
    .catch((e) => {
      console.error(`An exception occurred when trying to retrieve log entries: `, e);
      this.onData(null);
    });
  }

  onLogEntryFormChanged(data) {
    this.setState((currentState) => {
      let logEntryData = currentState.newLogEntry;
      logEntryData[data.name] = data.value;

      return {
        ...currentState,
        newLogEntry: logEntryData
      }
    });
  }

  submitNewLogEntry() {
    // TODO_jwir3: We need some way of indicating that the form is in the process of being submitted.
    let date_time = this.state.newLogEntry.date_time;
    // 2022-11-24T22:23:59.644Z
    console.log(moment(date_time).format());

    let new_log_entry_obj = {
      "created_at": moment(date_time).format(),
      "notes": this.state.newLogEntry.notes
    };

    this.api.addLogEntry(new_log_entry_obj)
      .then(() => {
        this.refreshLogEntries();

        if (this.addLogEntryModalRef && this.addLogEntryModalRef.current) {
          this.addLogEntryModalRef.current.closeModal();
        }
      })
      .catch((err) => {
        console.error("Unable to add new log entry: ", err);
      });
  }

  onBatchData(data) {
    let batchSelections = [
      {
        "value": null,
        "label": "No Batch"
      }
    ];

    for (let dataItemIdx in data.items) {
      let dataItem = data.items[dataItemIdx];
      batchSelections.push({
        value: dataItem.id,
        label: dataItem.batch_code
      });
    }

    this.setState({
      batches: batchSelections
    });
  }

  onData(data) {
    let processedData = null;

    if (data && data.items) {
      processedData = [];
      for(let dataItemIdx in data.items) {
        let dataItem = data.items[dataItemIdx];
        let batchCode = 'N/A';
        if (dataItem.batch) {
          let href = `/batches/${dataItem.batch.batch_code}`;
          batchCode = (<a href={href}>{dataItem.batch.batch_code}</a>);
        }

        let created_at = moment(dataItem.created_at).format('MM-DD-YYYY, h:mm:ss a');
        let timeframe = moment(dataItem.created_at).fromNow();

        processedData.push({
          created_at: created_at,
          timeframe: timeframe,
          batchCode: batchCode,
          notes: dataItem.notes
        });
      }
    }

    this.setState({
      dataAcquired: true,
      data: processedData
    });
  }

  openAddLogEntryModal() {
    if (this.addLogEntryModalRef && this.addLogEntryModalRef.current) {
      this.addLogEntryModalRef.current.openModal();
    }

    this.setState((currentState) => {
      return {
        ...currentState,
        newLogEntry: {}
      };
    });
  }

  render() {
    let dataTableColumns = [
      {
        "id": "created_at",
        "title": "Created At"
      },
      {
        "id": "timeframe",
        "title": "Timeframe"
      },
      {
        "id": "batchCode",
        "title": "Batch Code"
      },
      {
        "id": "notes",
        "title": "Notes"
      }
    ];

    let placeholderIconMessage = (
      <IconMessageWithTitle
        title="No Logs Available"
        description="There are no logs available for the given filters you have specified. Please try removing filters or adding log entries."
        iconName="LogbookSlash"
      />
    );

    let placeholderSpinner = (
      <div className="spinner-placeholder">
        <Icons.Spinner fill={colors.charcoal} />
      </div>
    );

    let shouldDisplayPlaceholder = (!this.state.dataAcquired || (this.state.dataAcquired && !this.state.data));
    let placeholder = this.state.dataAcquired && !this.state.data ? placeholderIconMessage : placeholderSpinner;

    let dataTable = (
      <DataTable
        columnDefinitions={dataTableColumns}
        data={this.state.data}
        />
    );

    return (
      <React.Fragment>
        <ToolContainer>
          <Toolbelt>
            <Tool
              icon={<Icons.AddLogEntry size="34px" />}
              label="Add Log Entry"
              name="add-log-entry"
              onClick={this.openAddLogEntryModal}
            />
          </Toolbelt>
          <section className="tool">
            <div className="main">
              <h1>Logbook</h1>
              { shouldDisplayPlaceholder && placeholder }
              { !shouldDisplayPlaceholder && dataTable }
            </div>
          </section>
        </ToolContainer>
        <ModalPopup
          ref={this.addLogEntryModalRef}
          title="Add New Log Entry"
          actionButton={{
            "title": "Create",
            "onClick": () => {
              this.submitNewLogEntry();
            }
          }
          }

          cancelButton={{
            "title": "Cancel",
            "shouldClose": true,
            "onClick": () => {
              console.log("Log entry addition cancelled.");
            }
          }}
        >
          <div className="add-log-entry-form">
            <AddLogEntryForm
              events={this.state.events}
              batches={this.state.batches}
              onChange={this.onLogEntryFormChanged}
            />
          </div>
        </ModalPopup>
      </React.Fragment>
    );
  }
}
