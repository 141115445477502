import _ from 'lodash';

export class LibraryUtil {
  static hydrateIngredient(api, obj) {
    if (obj.attributes) {
      // It's already hydrated.
      return obj;
    }

    const hydratedObj = api.getIngredientByTypeAndId(obj.type, obj.id);
    return {
      ...obj,
      attributes: hydratedObj.attributes
    };
  }

  static getLibraryObjectDataAttributeByKey(libraryObj, attributeName) {
    const dataCells = LibraryUtil.getLibraryDataCellsInOrder(libraryObj);
    return LibraryUtil.getLibraryDataAttributeByKey(dataCells, attributeName);
  }

  static getLibraryDataAttributeByKey(dataCells, attributeName) {
    const obj =  _.find(dataCells, (attrib) => {
      return attrib.key === attributeName;
    });

    if (obj) {
      return obj;
    }

    return null;
  }

  static getLibraryDataCellsInOrder(libraryObj) {
    let dataCells = [];

    if (!libraryObj) {
      return dataCells;
    }

    _.each(libraryObj.attributes, (attributeObj) => {
      let attributeKey = Object.keys(attributeObj)[0];
      let attributeValue = attributeObj[attributeKey];
      dataCells.push({
        key: attributeKey,
        value: attributeValue
      });
    });

    return dataCells;
  }

  static getCompositeKeyForLibraryObject(dataObject, attributesToInclude) {
    let compositeKey = '';

    for (let attrIdx in attributesToInclude) {
      const dataCells = LibraryUtil.getLibraryDataCellsInOrder(dataObject);
      const attribute
        = LibraryUtil.getLibraryDataAttributeByKey(dataCells,
                                                   attributesToInclude[attrIdx]);
      compositeKey = compositeKey.length === 0
        ? `${attribute.value}` : `${compositeKey}-${attribute.value}`;
    }

    return compositeKey;
  }

  static getLoginAuthorizationHeader(username, password) {
    let data = `${username}:${password}`;
    let buffer = new Buffer(data);
    let encodedAuth = buffer.toString('base64');
    return `Basic ${encodedAuth}`;
  }
}
