export class MeasureUtil {
  static convertToPounds(amount) {
    switch (amount.unit) {
      case 'ounces':
        return Number(amount.value) / 16.0;
      case 'pounds':
        return Number(amount.value);
      default:
        throw new Error(`${amount.value} does not appear to be convertable to pounds`);
    }
  }

  static convertToGallons(size) {
    if (!size) {
      return size;
    }

    // If we don't have a unit, then just return the value.
    if (!size.unit) {
      return size.amount;
    }

    switch (size.unit.value) {
      case 'gallons':
        return size.amount;
      case 'liters':
        return size.amount * .264172;
      case 'cup':
        return size.amount * 0.0625;
      case 'ounces':
        return size.amount * 0.0078125;
      case 'teaspooons':
        return size.amount * 0.00130208;
      case 'tablespoons':
        return size.amount * 0.00390625;
      default:
        throw new Error(`${size.unit.value} does not appear to be a measure of volume`);
    }
  }
}
