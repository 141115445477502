import React from "react";

import { BannerLayout, IconMessageWithTitle } from '@foamfactory/aegir';

import './ErrorView.scss';

export class ErrorView extends React.PureComponent {
    render() {
            return (
            <BannerLayout
                title="Issue Detected"
                bannerImage="Error"
            >
                <IconMessageWithTitle
                    iconName="BarrelWithBrokenTap"
                    title={this.props.title}
                    description={this.props.description}
                />
            </BannerLayout>
            );
    }
}