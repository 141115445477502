import React from 'react';

import {
  Icons,
  RecipeContainer,
  Tool,
  Toolbelt,
  ToolContainer } from '@foamfactory/aegir';

import { BeerStylesDAO } from '../../data/BeerStylesDAO';

import './RecipeBook.scss';

export class RecipeBook extends React.PureComponent {
  constructor(props) {
    super(props);

    this.preProcessRecipes = this.preProcessRecipes.bind(this);

    this.state = {
      recipes: [
        {
          name: "Damned Squirrel",
          version: 3,
          category: {
            reference: 'bjcp',
            style: '13',
            subStyle: 'b'
          },
          ibu: 28.10,
          srm: 21.44,
          numTimesBrewed: 1,
          abv: 4.99,
          lastBrewDate: "2021-01-29"
        },
        {
          name: "Whipped Cloud",
          category: {
            reference: 'bjcp',
            style: '1',
            subStyle: 'c'
          },
          version: 3,
          ibu: 19.17,
          srm: 4.83,
          abv: 4.66,
          lastBrewDate: '2021-01-22',
          numTimesBrewed: 1
        },
        {
          name: "Red Sunset",
          version: 3,
          abv: 4.27,
          ibu: 25.03,
          srm: 10.37,
          lastBrewDate: '2021-01-16',
          numTimesBrewed: 1
        },
        {
          name: 'Dobroy Nochi',
          version: 2,
          abv: 11.12,
          ibu: 67.1,
          srm: 38.51,
          lastBrewDate: '2018-08-16',
          numTimesBrewed: 2
        },
        {
          name: 'Anti-Scurvy Elixir',
          version: 1,
          category: {
            reference: 'bjcp',
            style: '21',
            subStyle: 'a'
          },
          abv: 5.72,
          ibu: 62.97,
          srm: 13.6,
          lastBrewDate: '2020-05-21',
          numTimesBrewed: 2
        },
        {
          name: 'The Hessian',
          version: 1,
          category: {
            reference: 'bjcp',
            style: '30',
            subStyle: 'b'
          },
          abv: 6.60,
          ibu: 23.4,
          srm: 20.87,
          lastBrewDate: '2020-08-31',
          numTimesBrewed: 1
        },
        {
          name: 'Smoking on 36th Street',
          version: 1,
          category: {
            reference: 'bjcp',
            style: '20',
            subStyle: 'a'
          },
          abv: 5.16,
          ibu: 30.14,
          srm: 30.08,
          lastBrewDate: '2020-08-14',
          numTimesBrewed: 2
        },
        {
          name: 'The Patriot',
          version: 3,
          category: {
            reference: 'bjcp',
            style: '4',
            subStyle: 'c'
          },
          abv: 6.46,
          ibu: 25.74,
          srm: 7.48,
          lastBrewDate: '2018-12-04',
          numTimesBrewed: 2
        },
        {
          name: 'SeptembreFiesta',
          version: 1,
          category: {
            reference: 'bjcp',
            style: '4',
            subStyle: 'b'
          },
          abv: 5.91,
          ibu: 21.83,
          srm: 6.79,
          lastBrewDate: '2016-08-11',
          numTimesBrewed: 1
        }
      ]
    };

    this.stylesDao = new BeerStylesDAO();
  }

  preProcessRecipes(recipes) {
      let processedRecipes = recipes.map((recipe) => {
        let categoryName = this.stylesDao.getStyle(recipe.category).name;
        return {
          name: recipe.name,
          version: recipe.version,
          category: categoryName,
          abv: recipe.abv,
          ibu: recipe.ibu,
          srm: recipe.srm,
          lastBrewDate: recipe.lastBrewDate,
          numTimesBrewed: recipe.numTimesBrewed
        }
      });

      return processedRecipes;
  }

  render() {
    let recipes = this.preProcessRecipes(this.state.recipes);

    return (
      <ToolContainer>
        <Toolbelt underTopMenu="true">
          <Tool icon={<Icons.Add size="34px" />} name="add-new-recipe" label="Add Recipe" />
          <Tool icon={<Icons.AddFolder size="34px" />} name="add-new-folder" label="Add Folder" />
          <Tool icon={<Icons.Trash size="34px" />} name="trash" label="Trash" bottom />
        </Toolbelt>
        <section className="tool">
          <div className="main">
            <h1>Recipe Book</h1>
            <RecipeContainer
              viewMode="grid"
              recipes={recipes}
            />
          </div>
        </section>
      </ToolContainer>
    );
  }
}
